import { useApplicationPage } from '../../../hooks/useApplicationPage.js';

import Modal from '../modal/modal.js';
import tg from '../../../img/product/imgtg.png';
import './product.scss';


const ProductList = () => {
    const dataProduct = [
            {
                title: 'Telegram Premium на 6 месяцев',
                img: tg,
                description:'Приобретенный товар отправляется подарком на указанное имя пользователя в Telegram', 
                type: 'Гифт',
                prise: 592,
                status: true,
                availability: 15,
                id: 3
            },
            {
                title: 'Telegram Premium на 1 год',
                img: tg,
                description:'Приобретенный товар отправляется подарком на указанное имя пользователя в Telegram', 
                type: 'Гифт',
                prise: 1048,
                status: true,
                availability: 22,
                id: 4
            },
            {
                title: 'Telegram Premium на 3 месяца',
                img: tg,
                description:'Приобретенный товар отправляется подарком на указанное имя пользователя в Telegram', 
                type: 'Гифт',
                prise: 334,
                status: false,
                availability: 19,
                id: 2
            },
            {
                title: 'Telegram Premium на 1 месяц', 
                img: tg, 
                description:'Приобретенный товар будет активирован на вашей учетной записи в Telegram', 
                type: 'Активация', 
                prise: 189,
                status: false, 
                availability: 12, 
                id: 1
            },
        ] 


    function renderItems (data) {
        const items = data.map((item, i) => {
            return <Product key={i} item={item}/>
        })
    return items}


    const productListItems = renderItems(dataProduct);
    return(
        <>
        <div className="product-list">
            <div className="product-list__inner">
                {productListItems}
            </div>
        </div>
        </>
    )
}

const Product = (props) => {
    const check_mark = () => {
        return <span>
        <svg height="14" fill='#25d26e' viewBox="0 0 48 48" width="14" xmlns="http://www.w3.org/2000/svg"><path d="M0 0h48v48H0z" fill="none"/><path d="M24 4C12.95 4 4 12.95 4 24c0 11.04 8.95 20 20 20 11.04 0 20-8.96 20-20 0-11.05-8.96-20-20-20zm-4 30L10 24l2.83-2.83L20 28.34l15.17-15.17L38 16 20 34z"/></svg>
        Есть в наличии
        </span>
    }
    const reject = () => {
        return <span>
        <svg height="14" fill='#ee5253' viewBox="0 0 48 48" width="14" xmlns="http://www.w3.org/2000/svg"><path d="M0 0h48v48H0z" fill="none"/><path d="M14 22v4h20v-4H14zM24 4C12.95 4 4 12.95 4 24s8.95 20 20 20 20-8.95 20-20S35.05 4 24 4zm0 36c-8.82 0-16-7.18-16-16S15.18 8 24 8s16 7.18 16 16-7.18 16-16 16z"/></svg>
        Нет в наличии
        </span>
    }
    const {title, prise, status, img, id} = props.item;
    const {selectedModalStatus, modal} = useApplicationPage();
    const available = status ? check_mark() : reject();
    const btnBuy = status ? 'product-list__true' : 'product-list__false';
    const link = status ? '/' : undefined;
    return(
        <>
        <div
        onClick={status ? e => selectedModalStatus(e) : null}
        key={id} 
        className="product-list__block">
            <div className="product-list__image">
                <img src={img} alt={title}/>
            </div>
            <div className="product-list__content">
                <div className="product-list__title">
                    <h2>{title}</h2>
                    {available}
                </div>
                <div className="product-list__btns">
                    <h4>{prise}<span>₽</span></h4>
                    <span href={link} className={btnBuy}>Купить</span>
                </div>
            </div>
        </div>
        <Modal 
            selectedModalStatus={selectedModalStatus}
            modal={modal}
            item={props.item}/>
        </>
    )
}

export default ProductList;