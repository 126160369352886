import { useApplicationPage } from '../../../hooks/useApplicationPage.js';

import './footer.scss';


const Footer = () => {
    const {scrollToBlock} = useApplicationPage()
    const clazz = '.pr-bg'
    return(
    <footer className="footer">
        <div className="container">
            <div className="footer__inner">
                <div className="footer__title">
                    <a href="https://premboost.ru/"><span>PREM</span>BOOST</a>
                </div>
                <nav className="footer__nav">
                    <a href="https://t.me/suponls24_bot" rel="nofollow noreferrer" target="_blank">Поддержка</a>
                    <a href="https://vk.com/topic-226817460_52692013" rel="nofollow noreferrer" target="_blank">Отзывы</a>
                    <a href="https://vk.com/prembost24" rel="nofollow noreferrer" target="_blank">Сообщество</a>
                    <a href="/" onClick={e => scrollToBlock(e,clazz)}>Гарантии</a>
                </nav>
            </div>
            <h5 className="footer__description">Улучшите свои возможности в Telegram купивши гифт для активации Premium в нашем магазине. Сделайте свои эмодзи ещё лучше, загружайте большие файлы, пользуйте эксклюзивным контентом и получите множество других бонусов.</h5>
            <div className="footer__copyrite">@ 2024 <a href="https://premboost.ru/">PREMBOOST

</a></div>
        </div>
    </footer>
    )
}

export default Footer;