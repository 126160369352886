import { useState, useEffect } from 'react'; 
import './faq.scss';


const Faq = () => {
    const faq = [
        {title: 'Как мне узнать мой логин Telegram?', description: <>Ваше имя пользователя в Telegram можно узнать в настройках; информация будет расположена рядом с вашим мобильным номером в строке «Имя пользователя». Пример: <a href='https://t.me/PremiumBot' target='_blank' rel='noreferrer'>@PremiumBot</a><br/><br/>Обратите внимание: username в Telegram пишется со знаком «@» в начале.</>, id: 1},
        {title: 'Как вы активируете Premium?', description: <>Активация товара осуществляется путем отправки гифта на вашу учетную запись.</>, id: 2},
        {title: 'Какой график отправки?', description: <>24/7, но мы работаем в полуавтоматическом режиме, поэтому предупреждаем, что задержка отправки может составлять от 10 минут до 2 часов.</>, id: 3},
        {title: 'Сработает ли на аккаунтах, где уже есть подписка?', description: <>
        Ваш аккаунт не должен иметь активной подписки Telegram Premium. Проверить наличие подписки вы можете через <a href="https://t.me/PremiumBot" target='_blank' rel='noreferrer'>@PremiumBot</a>
        </>, id: 4},
        {title: 'Есть ли региональные ограничения?', description: <>
        На данный момент отсутствуют.
        </>, id: 5},
    ]
    const {0: activeTab, 1: setActiveTab} = useState(null);


    function renderItems(data){
        const items = data.map((item, i) => {
            return <View key={i} item={item} activeTab={activeTab} setActiveTab={setActiveTab}/>
        })
        return items;
    }
    const content = renderItems(faq);
    return(
        <div className="block-faq"> 
            {content} 
        </div>
    )
}


const View = (props) => {
    const {title, description, id } = props.item;
    const {setActiveTab, activeTab } = props
    const {0: req, 1: setReq } = useState(false);
    const selectedReq = () => {
        if (!req && id !== activeTab){
            setReq(true);
            setActiveTab(id);
        }else{
            setReq(false);
            setActiveTab(null);
        }
    }
    useEffect(() => {
        if (activeTab !== id && req) {
          setReq(false);
        }
      }, [activeTab, id, req, setReq]);
    const clazzReq = req ? 'block-faq__request active' : 'block-faq__request';
    const clazzQue = req ? 'block-faq__quest active' : 'block-faq__quest';
    return(
        <div className="block-faq_inner">
            <div className={clazzQue} onClick={selectedReq} >
                {title}
                {activeTab === id && (
                    <p className={clazzReq}>{description}</p>
                )}
            </div>
        </div>
    )
}

export default Faq;